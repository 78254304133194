<!-- ======= Header ======= -->
<header *ngIf="router.url!='/home'" class="header">

  <nav class="navbar navbar-expand-md navbar-dark">
    <a class="navbar-brand" routerLink="/home"><img src="assets/images/logo.png"></a>
    <button class="navbar-toggler menu-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <i class="fa fa-bars" aria-hidden="true"></i>
    </button>
    <div class="main-head">
      <div class="collapse navbar-collapse justify-content-end top-head" id="collapsibleNavbar">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a class="nav-link" routerLink="/home"></a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" routerLink="./hire-jb">Hire from Jaibharath</a>
          </li>
          <!-- <li class="nav-item active">
            <a class="nav-link" routerLink="./students-feedback">Students Feedback</a>
          </li> -->
          
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle " id="navbardrope" data-toggle="dropdown" href="#">Events</a>
            <div class="dropdown-menu">
              <a class="nav-link" routerLink="./webinar">Webinar</a>
              <a class="nav-link" routerLink="./news-events">Blog</a>
              <a class="nav-link" routerLink="./news-events">Events</a>

            </div>
          </li>
          <!-- <li class="nav-item active">
            <a class="nav-link" routerLink="./news-events">News & Events</a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" routerLink="./webinar">Webinar</a>
          </li> -->
          <li class="nav-item active">
            <a class="nav-link" routerLink="./carrer-withus">Career</a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" routerLink="./online-admission">Online Admission</a>
          </li>
          <li class="nav-item" *ngIf="datalistService.sessionData" [routerLinkActive]="['active']">
            <a class="nav-link" [routerLink]="['/my-account']">My
              Account</a>
          </li>
          <li class="nav-item" *ngIf="datalistService.sessionData" [routerLinkActive]="['active']">
            <a class="nav-link" (click)="logout()">Logout</a>
          </li>
          <li class="nav-item active">
            <a class="nav-link drop-menu" routerLink="./student-portal">Students Portal</a>
          </li>
        </ul>
      </div>
      <div class="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle drop-menu course-icon" href="#" id="navbardrop" data-toggle="dropdown">
              Courses
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="#" routerLink="./marketing">MBA In Marketing</a>
              <a class="dropdown-item" href="#" routerLink="./human-resource">MBA In Human Resource</a>
              <a class="dropdown-item" href="#" routerLink="./international-business">MBA In International Business</a>
              <a class="dropdown-item" href="#" routerLink="./information-system">MBA In Information System</a>
              <a class="dropdown-item" href="#" routerLink="./finance">MBA In Finance</a>
              <a class="dropdown-item" href="#" routerLink="./operation-management">MBA In Operations Management</a>


            </div>
          </li>
          <li class="nav-item active">
            <a class="nav-link" routerLink="/home">Home</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle " id="navbardrope" data-toggle="dropdown" href="#">About Us</a>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="#" routerLink="./about">About College</a>
              <a class="dropdown-item" href="#" routerLink="./about-group">About Group</a>
              <a class="dropdown-item" href="#" routerLink="./about">Affiliations</a>
              <a class="dropdown-item" href="#" routerLink="./about">Partners</a>
              <a class="dropdown-item" href="#" routerLink="./faculty-profile">Faculty Profile</a>
              <a class="dropdown-item" href="#" routerLink="./gallery">Gallery</a>
              <!-- <a class="dropdown-item" href="#" routerLink="./about">MOU</a> -->

            </div>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle " id="navbardrope" data-toggle="dropdown" href="#">Placements</a>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="#" routerLink="./career">Career Enhancement Program</a>
              <a class="dropdown-item" href="#" routerLink="./placements">Job Fair</a>
              <a class="dropdown-item" href="#" routerLink="./placement-rpt">Placement Report</a>
              <a class="dropdown-item" href="#" routerLink="./placements">Our Recruiter</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle " id="navbardrope" data-toggle="dropdown" href="#">Facilities</a>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="#" routerLink="./library" (click)="common.facility(1);">Library</a>
              <a class="dropdown-item" href="#" routerLink="./library" (click)="common.facility(2);">Hostel</a>
              <a class="dropdown-item" href="#" routerLink="./library" (click)="common.facility(3);">Transportation</a>
              <a class="dropdown-item" href="#" routerLink="./library" (click)="common.facility(4);">Canteen</a>
              <a class="dropdown-item" href="#" routerLink="./library" (click)="common.facility(5);">ATM</a>
              <a class="dropdown-item" href="#" routerLink="./library" (click)="common.facility(6);">Auditorium</a>

            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle " id="navbardrope" data-toggle="dropdown" href="#">Co-Activities</a>
            <div class="dropdown-menu">

              <p class="drop-head"><b> Academic Activities</b></p>
              <!-- <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(1);">Hackathon</a> -->
              <!--<a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(1);">NSS</a>-->
              <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(2);">Faculties
                Development Program</a>
              <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(3);">Entrepreneurship
                Development<br> Cell ( EDC )</a>
              <a class="dropdown-item" href="#" routerLink="./activities"
                (click)="common.activity(4);">Industry-Institute Interaction Cell </a>
              <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(5);">International
                Conference</a>
              <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(6);">Mock Recruitment
                Process </a>
              <p class="drop-head"><b>Non-Academic</b></p>
              <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(7);">Farewell</a>
              <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(8);">Annual
                Function</a>
              <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(9);">Sport Meet</a>
              <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(10);">Ignis</a>
              <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(11);">Festival
                Celebration</a>
              <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(12);">Freshers
                day</a>

            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" routerLink="./contact">Contact Us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="datalistService.isOpenNoti=!datalistService.isOpenNoti" ><i class="fa fa-bell" aria-hidden="true"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

</header>
<app-header *ngIf="router.url=='/home'"></app-header>
<router-outlet (activate)="onActivate($event)"></router-outlet>

<!--footer starts from here-->
<footer class="footer">
  <div class="container ">
    <div class="row">
      <div class=" col-md-2 col-6">
        <h5 class="headin5_amrc col_white_amrc pt2">Quick Links</h5>
        <ul class="footer_ul_amrc">
          <li><a href="" routerLink="./about">About The College </a></li>
          <li><a href="" routerLink="./about-group">About The Group</a></li>
          <li><a href="" routerLink="../contact">Contact Us</a></li>
          <li><a href="" routerLink="../carrer-withus">Careers</a></li>
          <li><a href="" routerLink="../hire-jb"> Hire From Jaibharath</a></li>
          <li><a href="">Our Campus</a></li>
          <li><a href="" routerLink="../anti-ragging"> Anti Ragging Cell</a></li>
          <li><a href="" routerLink="../grievance"> Grievance Form</a></li>
          <li><a href="" routerLink="./landing">Landing Page</a></li>
          <li><a href="" routerLink="./webinar">Upcoming Webinars</a></li>
          <li><a href="https://jaibharathmba.com/sunstone/index.html">JAIBHARATH | Apply for X programs powered by Sunstone</a></li>


        </ul>
      </div>
      <div class=" col-md-2 col-6">
        <h5 class="headin5_amrc col_white_amrc pt2">Main Links</h5>
        <ul class="footer_ul_amrc">
          <!--<li><a href="" data-toggle="modal" data-target="#myModal" routerLink="./">NIRF</a></li>
              <li><a href="" data-toggle="modal" data-target="#myModal1" routerLink="./">Disclosure</a></li>-->
          <li><a href="" routerLink="./privacy-policy">Privacy Policy</a></li>
          <!-- <li><a href="">Refund and Cancellation Policy</a></li> -->
          <li><a href="">Pricing Policy</a></li>
          <li><a href="" routerLink="./terms-conditions">Terms and Condition</a></li>
          <li><a href="" routerLink="./gallery">Gallery</a></li>
          <!--<li><a href="">Email: info@jaibharath.com</a></li>
              <li><a href="">Phone: +91 8590895992</a></li>-->
        </ul>
      </div>

      <div class=" col-md-2 col-6">
        <h5 class="headin5_amrc col_white_amrc pt2">Courses</h5>
        <h5 class="mba-title">MBA - Dual Specialization</h5>

        <ul class="footer_ul_amrc">
          <li><a href="" routerLink="./operation-management">Operations Management </a></li>
          <li><a href="" routerLink="./marketing">Marketing</a>,<a href="" routerLink="./finance"> Finance</a> </li>
          <li><a href="" routerLink="./human-resource">Human Resourse Management</a></li>
          <li><a href="" routerLink="./international-business">International Business </a></li>
          <li><a href="" routerLink="./information-system">Information Systems </a></li>

          <!--<li><a href="" routerLink="./mechanical-engineering">Mechanical Engineering </a></li>
              <li><a href="" routerLink="./civil-engineering">Civil Engineering</a></li>
              <li><a href="" routerLink="./computer-science">Computer Science & Engineering</a></li>
              <li><a href="" routerLink="./electronics-communication">Electronics & Communication Engineering</a></li>
              <li><a href="" routerLink="./electrical-electronics">Electrical & Electronics Engineering</a></li>-->

        </ul>
      </div>



      <!-- Modal -->
      <div class="modal fade" id="myModal" role="dialog">
        <div class="modal-dialog">

          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <h4 class="modal-title"></h4>
            </div>
            <div class="modal-body">
              <embed src="./assets/NIRF.pdf" width="800px" height="2100px" />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>

        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="myModal1" role="dialog">
        <div class="modal-dialog">

          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <h4 class="modal-title"></h4>
            </div>
            <div class="modal-body">
              <embed src="./assets/mandatory_engg.pdf" width="800px" height="2100px" />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>

        </div>
      </div>

      <div class=" col-md-3 col-6">
        <h5 class="headin5_amrc col_white_amrc pt2">MBA-Integrated for Readily available jobs</h5>
        <ul class="footer_ul_amrc">
          <li><a href="">SAP Global Certification </a></li>
          <li><a href="">Business English Communication Global Certification </a></li>
          <li><a href="">Aviaion </a></li>
          <li><a href="">Shipping and Logistics</a></li>
          <li><a href="">Energy Management (For B.Tech Graduates) </a></li>
          <li><a href="">Information Systems </a></li>
          <li><a href="">Digital Marketing </a></li>
          <li><a href="">Business Analytics </a></li>
          <li><a href="" data-toggle="modal" data-target="#myModal3" >Prospectus  </a></li>
        </ul>




        <!--<button class="ftr-btn" routerLink="./anti-ragging">Anti Ragging Complaints</button>
            <button class="ftr-btn" routerLink="./online-admission">Online Application Form</button>-->
        <!--<button class="ftr-btn btn-itpwcs">Itpwcs pgms</button>-->

      </div>
      <!-- Modal -->
      <div class="modal fade" id="myModal3" role="dialog">
        <div class="modal-dialog">

          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <h4 class="modal-title"></h4>
            </div>
            <div class="modal-body">
              <embed src="./assets/Prospects MBA 2021.pdf" width="800px" height="2100px" />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>

        </div>
      </div>


      <div class="col-md-3">
        <h5 class="headin5_amrc col_white_amrc pt2">Contact Info:</h5>
        <ul class="footer_ul_amrc">
          <li>Email: <a href=""> info@jaibharathmba.com</a></li>
          <li>Phone: <a href="tel:+91 72932 29051"> +91 72932 29051 </a></li>
          <!--<li><a href="" >Shipping and Logistics</a></li>
              <li><a href="" >Energy Management (For B.Tech Graduates) </a></li>
              <li><a href="" >Information Systems </a></li>-->
            <li>
              <button class="ftr-btn"><a href="http://www.jaibharathmba.com/assets/Mandatorymba2021.pdf"><i
                class="fa fa-download fa-fw"></i>Mandatory Disclosure</a></button>
            </li>
        </ul>
      </div>
    </div>

    <div class="row">

      <div class="col-md-3">
        <div class="footer-icon">
          <button class="ftr-btn" routerLink="./anti-ragging">Anti Ragging Complaints</button>
        </div>
      </div>
      <div class="col-md-3">
        <div class="footer-icon">
        <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=HY0ICsLZM0WnQgTyJ-2xXFrI8hi89mxPloo3kbNj0oFUNVMzSFg5SFUzNTZCWEo0NEE1MzZYTVlVUy4u" target="_blank">
        <button class="ftr-btn">Online Application Form</button>
        </a>
        </div>
        </div>
      <div class="col-md-3">
        <div class="footer-icon">
          <button class="ftr-btn"><a href="http://www.jaibharathmba.com/application_form.pdf"><i
                class="fa fa-download fa-fw"></i>Download Brochure</a></button>
        </div>
      </div>

      <div class="col-md-3">

        <div class="footer-icon">
          <button class="ftr-btn"><a href="" routerLink="../grievance">Grievance Form</a></button>
        </div>
      </div>
    </div>
  </div>



  <div class="container">

    <ul class="social_footer_ul">
      <li><a href="https://www.facebook.com/jaibharathsms/">
          <!--<i class="fab fa-facebook-f"></i>--><img src="assets/images/Social (1).png" />
        </a></li>
      <li><a href=""><img src="assets/images/Social (2).png" /></a></li>
      <li><a href="https://instagram.com/jaibharathsms?igshid=11e4docqvqqkn"><img
            src="assets/images/Social (3).png" /></a></li>
      <li><a href=""><img src="assets/images/Social (5).png" /></a></li>
      <li><a href="https://www.linkedin.com/in/jai-bharath-school-of-management-studies-8804601b0/"><img
            src="assets/images/Testimonial (1).png" class="linked" /></a></li>

    </ul>
    <p class="text-center ft">COPY RIGHT @JAI BHARATH. ALL RIGHTS RESERVED </p>
    <p class="footer-note">The roots of education are bitter but <span><b>the fruit is sweet</b></span></p>

  </div>

</footer>


<!--<div class="placement-main">
        <div class="container">
        <div class="grad-sec">
            <div class="row">
                <div class=" col-md-2 col-6 offset-0 offset-md-1">
                    <div class="grad-body">
                        <img src="assets/images/logos/pic1.png" />
                    </div>
                </div>
                <div class=" col-md-2 col-6">
                    <div class="grad-body">
                        <img src="assets/images/logos/pic2.png" />
                    </div>
                </div>
                <div class=" col-md-2 col-6">
                    <div class="grad-body">
                        <img src="assets/images/logos/pic3.png" />
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-6">
                    <div class="grad-body">
                        <img src="assets/images/logos/pic4.png" />
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-6">
                    <div class="grad-body">
                        <img src="assets/images/logos/pic5.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>-->