import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../api/database/database-operation.service';
import { CommonService } from '../../api/services/common.service';
import { DatalistService } from '../../api/services/datalist.service';
import { FunctionService } from '../../api/services/function.service';
import { ProceedWarningComponent } from '../../popup/proceed-warning/proceed-warning.component';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.css']
})
export class SiteLayoutComponent implements OnInit {

  constructor(
    public datalistService: DatalistService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public router: Router,
    private databaseOperationService: DatabaseOperationService,
    public common: CommonService,
  ) { }

  ngOnInit(): void {

var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/6775135caf5bfec1dbe57473/1iggl78mo';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();
}
  onActivate(event) {
    window.scroll(0, 0);
  }
  logout() {
    let dialogRef: MatDialogRef<ProceedWarningComponent> = this.matDialog.open(ProceedWarningComponent, {
      width: 'auto',
      disableClose: true,
      panelClass: 'custom-dialog-container',
      hasBackdrop: true,
    });
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.datalistService.sessionData = false;
        sessionStorage.clear()
        this.functionService.showProcessMessage("Logout successfully", true);
        this.router.navigateByUrl('/home');
      }
    });
  }
}
